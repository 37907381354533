<template>
    <div>
        <CustomTable
			ref="table"
            :id_table="idTable"
            :busy.sync="table_busy"
            primaryKey="documentlien_id"
			:transformer="['DocumentLienTransformer', '']"
			:base-filters="filters"
            :display_action_button="!horseFicheReadOnly"
            :checkboxes="!horseFicheReadOnly"
            :hrefsRoutes="config_table_hrefs"
			:date-filter="dateFilter"
			:dateRangeV2="true"
			:periodToShow="periodToShow"
			:persistDateFilter="true"
        />

        <b-modal :modal-class="'modal-pdf'" size="xl" ref="modalViewDocumentOrdonnance" hide-footer>
            <template slot="modal-header" slot-scope="{ close }">
                <h2 class="mx-auto my-auto">{{ $t('ordonnance.document_visualisation') }}</h2>
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
            </template>

			<OfflineMedia
				:filename="modal_document_filename"
				customClass="w-100"
			/>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Constants from 'Constants'

    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35'
    import CustomTable from "GroomyRoot/components/Table/CustomTable"

    import Documents from "@/mixins/Documents.js"
    import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"

	export default {
        name: "historiqueOrdonnance",
		mixins: [Documents, TableAction, Navigation],
        props: [
            'horse_id',
        ],
		data () {
            return {
                modal_document_filename: null,
                ordonnances: null,

				/* Configuration du tableau : */
                table_busy: true,/* Définit si la table n'est pas encore chargée */
                events_tab: {
                    'TableAction::DocumentReady': this.viewDocumentOrdonnance,
                    'TableAction::DeleteDocumentOrdonnance': this.deleteOrdonnance
                },
                /* Nom du cheval cliquable dans le tableau */
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
				},
				dateFilter: {
					column: 'document_date',
					start: null,
					end: null
				},
				periodToShow: ['personalise', 'semaine', 'semaine_derniere', 'jour', 'mois', 'mois_dernier', 'annee_en_cours']
            }
		},
		created() {
			const start = new Date(new Date().setMonth(new Date().getMonth() - 3))
			this.dateFilter.start = start
			this.dateFilter.end = new Date()
		},
        methods: {
            async viewDocumentOrdonnance(data) {
				if(this.asking_write_access) return

				if(!this.got_write_access) {
					this.interracting_data = data
					this.action_name = 'viewDocumentOrdonnance'
					return this.askPermissionAndroid()
				}

				const filename = data.document_filename

				if((Constants.IS_IOS_RUNNING || Constants.IS_AND_RUNNING)
					&& filename.includes('.pdf')
				) {
					const blobFile = await this.$sync.loadOnDemandFile(filename)
					let reader = new FileReader()
					reader.readAsDataURL(blobFile)

					reader.onloadend = () => {
						const base64Cleanedup = this.formatBase64String(reader.result)

						if(Constants.IS_IOS_RUNNING) {
							window.nativeComm.fetchNative(() => {
								EventBus.$emit('TableAction::stopSpin')
							}, {
								filename: filename,
								base64: base64Cleanedup
							}, 'previewPdfFile')
						}
						else if(Constants.IS_AND_RUNNING) {
							window.nativeComm.fetchNative(res => {
								EventBus.$emit('TableAction::stopSpin')
							}, base64Cleanedup, 'previewPdfDocument')
						}
					}
				}
				else {
					this.$refs['modalViewDocumentOrdonnance'].show()
                	this.modal_document_filename = filename
					EventBus.$emit('TableAction::stopSpin')
				}
            },
            async deleteOrdonnance(documents) {
                const lien_ids = this.getArrayObjProperty(documents, 'documentlien_id')

                this.deleteDocuments(lien_ids)
                .then(() => {
					this.successToast('toast.info_modif_succes')
                    this.$refs.table.refreshTable()
                })
                .catch((err) => {
					console.error(err)
                    this.failureToast('toast.info_save_failed')
                })
            }
        },
        computed: {
            idTable() {
                if(this.horse_id && this.horse_id > 0) {
                    return 'horse_ordonnances'
                }
                return 'ordonnances'
            },
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
			},
			filters() {
				let filters = {
					document: {
						column: 'document.document_id',
						operator: 'isNotEqualTo',
						value: 0
					}
				}

				if (this.horse_id) {
					filters.horse = {
						column: 'documentlien_horse',
						operator: 'isEqualTo',
						value: this.$sync.replaceWithReplicated('horse', this.horse_id)
					}
				}

				return filters
			}
        },
        components: {
            CustomTable,
			LoadingSpinner,
			OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia')
        }
	}
</script>
